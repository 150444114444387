import React, { FC } from 'react'
import { Modal as ANTDModal } from 'antd'
import { ModalProps } from 'antd/lib/modal'
import { ModalStyle } from '../../../style/styled/antd/Modal.style'

export const Modal: FC<ModalProps> & { confirm: typeof ANTDModal.confirm; error: typeof ANTDModal.error } = (props) => (
  <>
    <style jsx global>
      {ModalStyle}
    </style>
    <ANTDModal {...props} />
  </>
)
Modal.confirm = ANTDModal.confirm
Modal.error = ANTDModal.error
