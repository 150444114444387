import React, { FC } from 'react'
import { Button as ANTDButton } from 'antd'
import { ButtonStyle } from '../../../style/styled/antd/Button.style'
import { ButtonProps } from 'antd/lib/button'

export const Button: FC<ButtonProps & React.RefAttributes<HTMLElement>> = (props) => (
  <>
    <style jsx global>
      {ButtonStyle}
    </style>
    <ANTDButton {...props} />
  </>
)
